import React from "react";
import classes from "../styles/about.module.css";
import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Content from "../components/Content";
import Img from "gatsby-image/withIEPolyfill";

const title = "About | Avenue Barber";
const description = "Read our story here.";

export default function About({ data, location }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        {/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}

        {/* <meta property="og:url" content="PERMALINK" /> */}

        <meta property="og:site_name" content="Avenue Barber" />
        <link rel="canonical" href="https://avenuebarber.ca/about" />
      </Helmet>
      <StylesProvider injectFirst>
        <Layout data={data} location={location}>
          <div className={classes.imgContainer}>
            <Img
              alt="Photo of Michael and Angela"
              fluid={data.coverImage.childImageSharp.fluid}
              className={classes.img}
            />
          </div>
          <div className={classes.heading}>
            <p>
              Avenue Barber is a small 4 chair barbershop in the heart of Oak
              Bay Village. We are friendly, comfortable, clean and give quality
              haircuts for a reasonable price. Please call or book online with{" "}
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/michael"
                aria-label="Separate booking site for Michael Farrell"
                target="_blank"
                className={classes.michaelBooking}
              >
                Michael
              </a>
              {", "}
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/angela"
                aria-label="Separate booking site for Angela"
                target="_blank"
                className={classes.angelaBooking}
              >
                Angela
              </a>
              {", "}
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/eliska"
                aria-label="Separate booking site for Eliska"
                target="_blank"
                className={classes.eliskaBooking}
              >
                Eliska
              </a>
              {", "}
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/marilyn"
                aria-label="Separate booking site for Marilyn"
                target="_blank"
                className={classes.marilynBooking}
              >
                Marilyn
              </a>
              {" or "}
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/geoff-large"
                aria-label="Separate booking site for Geoff"
                target="_blank"
                className={classes.geoffBooking}
              >
                Geoff.
              </a>
            </p>
          </div>
        </Layout>
      </StylesProvider>
    </>
  );
}

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "about-main.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
