import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Img from "gatsby-image/withIEPolyfill";
import { graphql } from "gatsby";
import classes from "../styles/book.module.css";
import React, { useState } from "react";

const title = "Book | Avenue Barber";
const description = "Book an appointment with one of our barbers.";

export default function Book({ data, location }) {
  const [awayMessageToggle, setAwayMessageToggle] = useState(false);
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        {/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}

        {/* <meta property="og:url" content="PERMALINK" /> */}

        <meta property="og:site_name" content="Avenue Barber" />
        <link rel="canonical" href="https://avenuebarber.ca/book" />
      </Helmet>
      <StylesProvider injectFirst>
        <Layout data={data} location={location}>
          {location.pathname === "/book/" && (
            <div className={classes.away}>
              <div className={classes.michaelAway}>
                Michael will be away on medical leave from Feb 21 to May 26.
              </div>
              <div className={classes.angelaAway}>
                Angela will be away from Feb 2 to Feb 19.
              </div>
            </div>
          )}
          <div className={classes.imgContainerDesktop}>
            <Img
              className={classes.michaelDesktopImage}
              fluid={data.desktopMichaelBook.childImageSharp.fluid}
            />
            <Img
              className={classes.angelaDesktopImage}
              fluid={data.desktopAngelaBook.childImageSharp.fluid}
            />
            <Img
              className={classes.eliskaDesktopImage}
              fluid={data.desktopEliskaBook.childImageSharp.fluid}
            />
            <Img
              className={classes.marilynDesktopImage}
              fluid={data.desktopMarilynBook.childImageSharp.fluid}
            />
            <Img
              className={classes.geoffDesktopImage}
              fluid={data.desktopGeoffBook.childImageSharp.fluid}
            />
            <div className={classes.desktopButtonStrip}>
              <div className={classes.desktopButtonAbsoluteContainer}>
                <div className={classes.michaelButtonContainer}>
                  <a
                    rel="noreferrer noopener"
                    href="https://avenuebarber.setmore.com/michael"
                    aria-label="Separate booking site for Michael Farrell"
                    target="_blank"
                    className={classes.michaelBooking}
                  >
                    Book with Michael
                  </a>
                </div>
              </div>

              <div className={classes.desktopButtonAbsoluteContainer}>
                <div className={classes.angelaButtonContainer}>
                  <a
                    rel="noreferrer noopener"
                    href="https://avenuebarber.setmore.com/angela"
                    aria-label="Separate booking site for Angela"
                    target="_blank"
                    className={classes.angelaBooking}
                  >
                    Book with Angela
                  </a>
                </div>
              </div>

              <div className={classes.desktopButtonAbsoluteContainer}>
                <div className={classes.eliskaButtonContainer}>
                  <a
                    rel="noreferrer noopener"
                    href="https://avenuebarber.setmore.com/eliska"
                    aria-label="Separate booking site for Eliska"
                    target="_blank"
                    className={classes.eliskaBooking}
                  >
                    Book with Eliska
                  </a>
                </div>
              </div>

              <div className={classes.desktopButtonAbsoluteContainer}>
                <div className={classes.marilynButtonContainer}>
                  <a
                    rel="noreferrer noopener"
                    href="https://avenuebarber.setmore.com/marilyn"
                    aria-label="Separate booking site for Marilyn"
                    target="_blank"
                    className={classes.marilynBooking}
                  >
                    Book with Marilyn
                  </a>
                </div>
              </div>
              <div className={classes.desktopButtonAbsoluteContainer}>
                <div className={classes.geoffButtonContainer}>
                  <a
                    rel="noreferrer noopener"
                    href="https://avenuebarber.setmore.com/geoff-large"
                    aria-label="Separate booking site for Geoff"
                    target="_blank"
                    className={classes.geoffBooking}
                  >
                    Book with Geoff
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.imgContainerMobile}>
            <Img
              className={classes.image}
              fluid={data.mobileMichaelBook.childImageSharp.fluid}
            />

            <div className={classes.michaelButtonContainer}>
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/michael"
                aria-label="Separate booking site for Michael Farrell"
                target="_blank"
                className={classes.michaelBooking}
              >
                Book with Michael
              </a>
            </div>
          </div>
          <div className={classes.imgContainerMobile}>
            <Img
              className={classes.image}
              fluid={data.mobileAngelaBook.childImageSharp.fluid}
            />

            <div className={classes.angelaButtonContainer}>
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/angela"
                aria-label="Separate booking site for Angela"
                target="_blank"
                className={classes.angelaBooking}
              >
                Book with Angela
              </a>
            </div>
          </div>

          <div className={classes.imgContainerMobile}>
            <Img
              className={classes.image}
              fluid={data.mobileEliskaBook.childImageSharp.fluid}
            />

            <div className={classes.eliskaButtonContainer}>
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/eliska"
                aria-label="Separate booking site for Eliska"
                target="_blank"
                className={classes.eliskaBooking}
              >
                Book with Eliska
              </a>
            </div>
          </div>

          <div className={classes.imgContainerMobile}>
            <Img
              className={classes.image}
              fluid={data.mobileMarilynBook.childImageSharp.fluid}
            />

            <div className={classes.marilynButtonContainer}>
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/marilyn"
                aria-label="Separate booking site for Marilyn"
                target="_blank"
                className={classes.marilynBooking}
              >
                Book with Marilyn
              </a>
            </div>
          </div>
          <div className={classes.imgContainerMobile}>
            <Img
              className={classes.image}
              fluid={data.mobileGeoffBook.childImageSharp.fluid}
            />

            <div className={classes.geoffButtonContainer}>
              <a
                rel="noreferrer noopener"
                href="https://avenuebarber.setmore.com/geoff-large"
                aria-label="Separate booking site for Geoff"
                target="_blank"
                className={classes.geoffBooking}
              >
                Book with Geoff
              </a>
            </div>
          </div>
        </Layout>
      </StylesProvider>
    </>
  );
}

export const query = graphql`
  query {
    mobileMichaelBook: file(relativePath: { eq: "book-michael-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopMichaelBook: file(relativePath: { eq: "book-michael-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileAngelaBook: file(relativePath: { eq: "book-angela-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopAngelaBook: file(relativePath: { eq: "book-angela-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileLexiBook: file(relativePath: { eq: "book-lexi-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopLexiBook: file(relativePath: { eq: "book-lexi-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileEliskaBook: file(relativePath: { eq: "book-eliska-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopEliskaBook: file(relativePath: { eq: "book-eliska-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileMarilynBook: file(relativePath: { eq: "book-marilyn-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopMarilynBook: file(relativePath: { eq: "book-marilyn-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileGeoffBook: file(relativePath: { eq: "book-geoff-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 767, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopGeoffBook: file(relativePath: { eq: "book-geoff-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
